import type { IMapTile } from '@/features/map/map-data-v5/map-types';

import MapAccommodationTileDesktop from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-desktop';
import MapHighlightTileDesktop from '@/features/map/map-components/map-tiles/highlight-tile-desktop/map-highlight-tile-desktop';
import MapPlaceTileDesktop from '@/features/map/map-components/map-tiles/place-tile-desktop/place-tile-desktop';
import MapSchoolTileDesktop from '@/features/map/map-components/map-tiles/school-tile-desktop/map-school-tile-desktop';
import MapSpotTileDesktop from '@/features/map/map-components/map-tiles/spot-tile-desktop/map-spot-tile-desktop';
import { useMapPricesLoading } from '@/features/map/map-data-v5/use-map-data-v5';

export default function MapTileSwitchDesktop({ activeTile }: { activeTile: IMapTile | null }) {
    const areMapPricesLoading = useMapPricesLoading();

    if (!activeTile) {
        return null;
    }

    switch (activeTile?.type) {
        case 'spot':
            return <MapSpotTileDesktop {...activeTile} />;
        case 'accommodation': {
            return (
                <MapAccommodationTileDesktop
                    {...activeTile}
                    isLoading={areMapPricesLoading}
                />
            );
        }
        case 'school':
            return <MapSchoolTileDesktop {...activeTile} />;
        case 'highlight':
            return <MapHighlightTileDesktop {...activeTile} />;
        case 'place':
            return <MapPlaceTileDesktop {...activeTile} />;
        default:
            return null;
    }
}
