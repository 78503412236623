import { dataLayerPush } from '@/core/features/tracking/google-data-layer';

export function trackSpotResultClick({
    activity,
    activitySpot,
    city,
    isMap,
}: {
    activity?: string;
    activitySpot: string;
    city: string;
    isMap: boolean;
}) {
    dataLayerPush({
        activity,
        activitySpot,
        city,
        element: isMap ? 'map_activityspot' : 'spot',
        event: 'click',
        map: isMap ? 'yes' : 'no',
        spot: activitySpot,
    });
}
