import type { MapType } from '@/features/map/map-components/map-layer-switch/map-layer-switch';

import React from 'react';

import { useSetAtom } from '@/core/features/store/atom-store';
import { googleMapInstanceAtom, useGoogleMapInstance } from '@/features/map/google-map/google-map-state';

import styles from './google-map.module.scss';

export type GoogleMapProps = {
    children?: React.ReactNode;
    isBottomSheet?: boolean;
    mapType: MapType;
};

const GoogleMap_ = ({ children, isBottomSheet = false, mapType }: GoogleMapProps) => {
    const mapDivRef = React.useRef<HTMLDivElement>(null);
    const googleMapInstance = useGoogleMapInstance();
    const setGoogleMapInstance = useSetAtom(googleMapInstanceAtom);

    // keep for debugging
    // React.useEffect(() => {
    //     if (!googleMapInstance) {
    //         return;
    //     }
    //     window.googleMapInstance = googleMapInstance;
    // }, [googleMapInstance]);

    React.useEffect(() => {
        return () => {
            setGoogleMapInstance(null);
        };
    }, [setGoogleMapInstance]);

    React.useEffect(() => {
        if (mapDivRef.current && googleMapInstance === null) {
            const googleMap = new window.google.maps.Map(mapDivRef.current, {
                center: { lat: 48, lng: 8 },
                clickableIcons: false,
                fullscreenControl: false,
                gestureHandling: 'greedy',
                mapTypeControl: false,
                mapTypeId: mapType,
                maxZoom: 19,
                minZoom: 2,
                restriction: { latLngBounds: { east: 180, north: 85, south: -85, west: -180 } },
                scaleControl: true,
                streetViewControl: false,
                zoomControl: false,
            });
            setGoogleMapInstance(googleMap);
        }
    }, [mapDivRef, googleMapInstance, children, mapType, setGoogleMapInstance]);

    return (
        <div
            className={`flex height-100 ${!isBottomSheet ? 'safe-area-bottom' : ''} ${styles.mapWrapper}`}
            data-qa-id={'qa-google-map-wrapper'}
        >
            <div className={'width-100 height-100'}>
                <div
                    className={`absolute-full ${styles.googleMap}`}
                    ref={mapDivRef}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GoogleMap_);
