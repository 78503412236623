import type { CtaListOnClickInterface } from '@/core/components/cta-list/cta-list';
import type { OnClickEvent } from '@/core/types/react-overrides';
import type { MapAccommodationTileDesktopProps } from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-desktop';
import type { PropsWithChildren } from 'react';

import React from 'react';

import InlineOverlay from '@/core/features/modals/inline-overlay/inline-overlay';
import useLinkoutTracking from '@/features/linkout-tracking/use-linkout-tracking';
import MapAccommodationTileOverlay from '@/features/map/map-components/map-tiles/accommodation-tiles/accommodation-desktop/map-accommodation-tile-overlay/map-accommodation-tile-overlay';

export function MapAccommodationMplTileDesktop({
    accommodationTypeBadge,
    children,
    closeOverlay,
    isOverlayOpen,
    name,
    overlay,
    spotName,
    toggleOverlay,
}: PropsWithChildren<
    Pick<MapAccommodationTileDesktopProps, 'accommodationTypeBadge' | 'name' | 'overlay' | 'spotName'> & {
        closeOverlay: () => void;
        isOverlayOpen: boolean;
        toggleOverlay: (event: React.MouseEvent<HTMLDivElement>) => void;
    }
>) {
    const { trackLinkout } = useLinkoutTracking();

    const onTileClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        toggleOverlay(event);
    };

    const onClose = (event: OnClickEvent) => {
        event.stopPropagation();
        closeOverlay();
    };

    const onOverlayCtaClick: CtaListOnClickInterface = (event, cta) => {
        event.stopPropagation(); // do not close overlay
        trackLinkout({
            elementType: 'accommodation_tile_overlay_map',
            spot: spotName,
            trackActivities: true,
            verticalType: cta.type,
        });
    };

    return (
        <InlineOverlay
            isOpen={isOverlayOpen}
            onClick={onTileClickHandler}
            overlay={
                <MapAccommodationTileOverlay
                    accommodationTypeBadge={accommodationTypeBadge}
                    name={name}
                    onClose={onClose}
                    onOverlayCtaClick={onOverlayCtaClick}
                    overlay={overlay}
                />
            }
            reserveOverlaySpace={true}
        >
            {children}
        </InlineOverlay>
    );
}
