import type { IMapCluster, IMapMarker, IMapPoiIconRecord } from '@/features/map/map-data-v5/map-types';

import PoiClusterMarker from '@/features/map/map-components/map-markers/cluster-marker/poi-cluster-marker';
import SpotClusterMarker from '@/features/map/map-components/map-markers/cluster-marker/spot-cluster-marker';

type MapClusterSwitchProps = {
    icons: IMapPoiIconRecord | null;
    isActive: boolean;
    marker: IMapMarker;
    onClusterClick: (marker: IMapCluster) => void;
};

export default function MapClusterSwitch({ icons, isActive, marker, onClusterClick }: MapClusterSwitchProps) {
    if (marker.markerType === 'cluster' && icons) {
        const icon = icons[marker.pinIcon]?.[isActive ? 'active' : 'inactive'];

        if (marker.type === 'spot' && icon) {
            return (
                <SpotClusterMarker
                    icon={icon}
                    onClick={() => onClusterClick(marker)}
                    text={marker.count}
                />
            );
        }

        if ((marker.type === 'school' || marker.type === 'place') && icon) {
            return (
                <PoiClusterMarker
                    icon={icon}
                    onClick={() => onClusterClick(marker)}
                    text={marker.count}
                />
            );
        }

        return null;
    }

    if (marker.markerType === 'indicator') {
        return (
            <div
                className={'border-radius-circle bg-catalinaBlue'}
                // eslint-disable-next-line no-inline-styles/no-inline-styles
                style={{ height: 4, width: 4 }}
            />
        );
    }

    return null;
}
