import type { MapDataV5_jsonld_page_read } from '@/features/map/map-data-v5/map-data-v5-type';
import type { IMapLatLngLiteral } from '@/features/map/map-data-v5/map-types';

import React from 'react';

import { useClientUrl } from '@/core/features/app/app-atoms';
import useAppEvents from '@/core/features/app/use-app-events';
import { useRouterEvents } from '@/core/features/router/router-events';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';
import { deepEquals } from '@/core/utils/object';
import { getUrlPath } from '@/core/utils/url';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import { logMap } from '@/features/map/map-container/map-logger';
import { useMapLoadingState } from '@/features/map/map-container/map-state';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';
import { getMapLatLngLiteral } from '@/features/map/map-data-v5/map-data-v5-service';
import {
    mapBoundariesForComponentsAtom,
    mapDataV5DefaultAtom,
    shouldBlockInitMapBoundariesForComponentsAtom,
} from '@/features/map/map-data-v5/use-map-data-v5';

const lastMapPositionUrlPathAtom = atom<null | string>(null);

export const useMapPosition = () => {
    const mapDataDefault = useAtomValue(mapDataV5DefaultAtom);
    const { overlayHistoryMapStateData } = useMapOverlayHistoryState();
    const appEvents = useAppEvents();
    const routerEvents = useRouterEvents();
    const googleMapInstance = useGoogleMapInstance();
    const isMapLoading = useMapLoadingState();

    const [clientUrl] = useClientUrl();
    const clientUrlRef = React.useRef<string>(clientUrl);

    const isPositionInitializedRef = React.useRef(false);

    const [hasFilterChanged, setFilterChanged] = React.useState(false);
    const [hasDestinationChanged, setDestinationChanged] = React.useState(false);
    const [mapBoundariesForComponents, setMapBoundariesForComponents] = useAtom(mapBoundariesForComponentsAtom);
    const setBlockInitMapBoundariesForComponents = useSetAtom(shouldBlockInitMapBoundariesForComponentsAtom);
    const [lastMapPositionUrlPath, setLastMapPositionUrlPath] = useAtom(lastMapPositionUrlPathAtom);

    const lastMapDataDefaultMetaRef = React.useRef<MapDataV5_jsonld_page_read['meta'] | null>(
        mapDataDefault?.meta ?? null,
    );
    const [hasMapDataDefaultChanged, setMapDataDefaultChanged] = React.useState(false);

    const updateGoogleMapPosition = React.useCallback(
        (location?: IMapLatLngLiteral, zoom?: number) => {
            if (!googleMapInstance) {
                return;
            }
            const currentLocation = getMapLatLngLiteral(googleMapInstance.getCenter());

            if (location && !deepEquals(location, currentLocation)) {
                googleMapInstance.panTo({ lat: location.latitude, lng: location.longitude });
            }

            const currentZoom = googleMapInstance.getZoom();

            if (!currentZoom) {
                googleMapInstance.setZoom(Math.max(zoom ?? 2, 2)); // zoom has to be at least 2
                return;
            }

            // animated zoom
            if (zoom && currentZoom !== zoom) {
                const isZoomIn = zoom > currentZoom;
                const zoomDiff = zoom !== currentZoom ? Math.abs(zoom - currentZoom) : 0;

                logMap('move map to', { currentZoom, location, zoom, zoomDiff });

                if (zoomDiff > 0) {
                    Array.from({ length: zoomDiff + 1 }).forEach((_, index) => {
                        setTimeout(
                            () => {
                                googleMapInstance.setZoom(currentZoom + index * (isZoomIn ? 1 : -1));
                                if (location) {
                                    // keep position while zooming
                                    googleMapInstance.panTo({ lat: location.latitude, lng: location.longitude });
                                }
                            },
                            100 * index + 1,
                        );
                    });
                }
            }
        },
        [googleMapInstance],
    );

    const shouldFilterChangeZoom = React.useCallback((): boolean => {
        if (!mapDataDefault) {
            return false;
        }

        logMap('shouldFilterChangeZoom', { hasFilterChanged, hasMapDataDefaultChanged, mapBoundariesForComponents });

        if (!hasFilterChanged || !hasMapDataDefaultChanged || !googleMapInstance) {
            return false;
        }

        setFilterChanged(false);

        if (!mapBoundariesForComponents) {
            setBlockInitMapBoundariesForComponents(true);

            // always zoom, if the user has not moved the map, yet
            return true;
        }

        const currentZoom = googleMapInstance.getZoom();

        if (!currentZoom) {
            return false;
        }

        // apply a filter change that results in a zoomed in map
        return mapDataDefault.meta.defaultZoom > currentZoom;
    }, [
        googleMapInstance,
        hasFilterChanged,
        hasMapDataDefaultChanged,
        mapBoundariesForComponents,
        mapDataDefault,
        setBlockInitMapBoundariesForComponents,
    ]);

    const shouldDestinationChangeResetLocation = React.useCallback((): boolean => {
        if (!hasDestinationChanged || !hasMapDataDefaultChanged) {
            return false;
        }

        setDestinationChanged(false);

        return true;
    }, [hasDestinationChanged, hasMapDataDefaultChanged]);

    /**
     * Effect: Keep track of map data default changes
     */
    React.useEffect(() => {
        const lastValue = lastMapDataDefaultMetaRef.current;

        if (mapDataDefault?.meta) {
            lastMapDataDefaultMetaRef.current = mapDataDefault?.meta;
        }

        // logMap('mapDataDefault change', {
        //     equal: deepEquals(lastValue, mapDataDefault?.meta ?? null),
        //     lastValue,
        //     meta: mapDataDefault?.meta,
        // });

        if (!deepEquals(lastValue, mapDataDefault?.meta ?? null)) {
            setMapDataDefaultChanged(true);
        }
    }, [mapDataDefault?.meta]);

    /**
     * Effect: Update map position with history or default map data
     */
    React.useEffect(() => {
        if (isMapLoading || !googleMapInstance) {
            return;
        }

        // initialize overlay history state only if state is uninitialized and history state exisists
        if (
            !isPositionInitializedRef.current &&
            overlayHistoryMapStateData?.location &&
            overlayHistoryMapStateData.zoom !== undefined
        ) {
            logMap('Setting map position from overlay history state', overlayHistoryMapStateData);

            isPositionInitializedRef.current = true;

            const { location, mapBoundariesForComponents, zoom } = overlayHistoryMapStateData;

            if (!mapBoundariesForComponents) {
                setBlockInitMapBoundariesForComponents(true);
            } else {
                clearMapBoundariesForComponentsTimeout();
                setMapBoundariesForComponents(mapBoundariesForComponents);
            }
            return updateGoogleMapPosition(location, zoom);
        }

        const currentCenter = googleMapInstance.getCenter();
        const currentZoom = googleMapInstance.getZoom();

        const shouldResetLocation = shouldDestinationChangeResetLocation();
        const shouldZoom = shouldFilterChangeZoom();

        logMap('position effect', { hasMapDataDefaultChanged, shouldResetLocation, shouldZoom });

        if (hasMapDataDefaultChanged) {
            setMapDataDefaultChanged(false);
        }

        // update map position with default map data if it changed and user has not moved the map or filter change narrows result
        if (mapDataDefault && (!currentCenter || !currentZoom || shouldResetLocation || shouldZoom)) {
            logMap('Setting map position from default map data', {
                currentCenter,
                currentZoom,
                location: mapDataDefault.meta.defaultLocation,
                shouldResetLocation,
                shouldZoom,
                zoom: mapDataDefault.meta.defaultZoom,
            });

            isPositionInitializedRef.current = true;

            const { defaultLocation, defaultZoom } = mapDataDefault.meta;

            return updateGoogleMapPosition(defaultLocation, defaultZoom);
        }
    }, [
        googleMapInstance,
        hasDestinationChanged,
        hasMapDataDefaultChanged,
        isMapLoading,
        mapDataDefault,
        overlayHistoryMapStateData,
        setBlockInitMapBoundariesForComponents,
        setMapBoundariesForComponents,
        shouldDestinationChangeResetLocation,
        shouldFilterChangeZoom,
        updateGoogleMapPosition,
    ]);

    /**
     * Effect: reset map state when client url changes path (switching between pages) or on browser back
     */
    const wasPopstateListenerRegisteredRef = React.useRef(false);
    React.useEffect(() => {
        const routeChangeCompleteHandler = () => {
            logMap('routeChangeCompleteHandler');
            if (getUrlPath(clientUrlRef.current) === getUrlPath(clientUrl)) {
                return;
            }
            isPositionInitializedRef.current = false;
        };
        routerEvents.on('routeChangeComplete', routeChangeCompleteHandler);

        // register popstate handler without clearing it, as it is needed on consectutive page changes
        // but do only register it once
        if (!wasPopstateListenerRegisteredRef.current) {
            const popstateHandler = () => {
                logMap('popstateHandler');

                if (clientUrl === clientUrlRef.current) {
                    return; // nothing changed (e.g. filter overlay was closed without selecting anything)
                }
                isPositionInitializedRef.current = false;
            };

            window.addEventListener('popstate', popstateHandler);
        }
        wasPopstateListenerRegisteredRef.current = true;

        return () => {
            routerEvents.off('routeChangeComplete', routeChangeCompleteHandler);
        };
    }, [clientUrl, routerEvents]);

    /**
     * Effect: reset map state when path changes (e.g. switching from /tauchen to /wandern)
     */
    const resetMapBoundariesForComponentsTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const clearMapBoundariesForComponentsTimeout = () => {
        if (resetMapBoundariesForComponentsTimeoutRef.current === null) {
            return;
        }

        clearTimeout(resetMapBoundariesForComponentsTimeoutRef.current);
        resetMapBoundariesForComponentsTimeoutRef.current = null;
    };

    React.useEffect(() => {
        if (getUrlPath(clientUrl) === lastMapPositionUrlPath) {
            return;
        }
        logMap('Map Path changed');

        setLastMapPositionUrlPath(getUrlPath(clientUrlRef.current));
        clearMapBoundariesForComponentsTimeout();
        resetMapBoundariesForComponentsTimeoutRef.current = setTimeout(() => {
            setMapBoundariesForComponents(null);
        }, 800); // wait shortly for page transition
    }, [clientUrl, lastMapPositionUrlPath, setLastMapPositionUrlPath, setMapBoundariesForComponents]);

    /**
     * Effect: Register page events
     */
    React.useEffect(() => {
        const destinationChangeHandler = () => {
            logMap('destinationChangeHandler');
            setDestinationChanged(true);
            setMapBoundariesForComponents(null);
            setBlockInitMapBoundariesForComponents(true);
        };

        const filterChangeHandler = () => {
            logMap('filterChangeHandler');

            setFilterChanged(true);
        };

        appEvents.on('destination_change', destinationChangeHandler);
        appEvents.on('filter_change', filterChangeHandler);

        return () => {
            appEvents.off('destination_change', destinationChangeHandler);
            appEvents.off('filter_change', filterChangeHandler);
        };
    }, [appEvents, mapBoundariesForComponents, setBlockInitMapBoundariesForComponents, setMapBoundariesForComponents]);
};
