import type { BadgeType } from '@/core/components/badges/badge-type';

type BadgeDesktopProp = {
    badge: BadgeType;
    fontSize?: 'font-size-12' | 'font-size-14';
    padding?: 'padding-0' | 'padding-4';
};

export default function BadgeDesktop({ badge, fontSize = 'font-size-12', padding = 'padding-4' }: BadgeDesktopProp) {
    return (
        <div
            className={'flex-center ellipsis border-radius-2 cursor-default height-20'}
            key={badge.htmlText}
            style={{
                backgroundColor: badge.backgroundColor,
                border: badge.outlineColor ? `1px solid ${badge.outlineColor}` : undefined,
                color: badge.textColor,
            }}
        >
            <span className={`${padding} ${fontSize}`}>{badge.htmlText}</span>
        </div>
    );
}
