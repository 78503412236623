import type { TopLivePrice } from '@/features/top-live-price/top-live-price-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import { HtmlTextWrapper, IconImage, Link } from '@/core/features';
import useLinkoutTracking from '@/features/linkout-tracking/use-linkout-tracking';

import styles from './map-spot-tile-top-live-prices-desktop.module.scss';

type SpotTileTopLivePricesDesktopProps = {
    activity: string;
    activitySpotName: string;
    livePrices: TopLivePrice[];
    spotName: string;
};

export function MapSpotTileTopLivePricesDesktop({
    activity,
    activitySpotName,
    livePrices,
    spotName,
}: SpotTileTopLivePricesDesktopProps) {
    const { trackLinkout } = useLinkoutTracking();

    const handleTrackLinkout = (linkUrl: string) => {
        trackLinkout({
            activity,
            activitySpot: activitySpotName,
            city: spotName,
            elementType: 'spot_tile_map',
            linkUrl: linkUrl,
        });
    };
    return (
        <div className={`flex-column ${styles.livePrices}`}>
            {livePrices.map((livePrice, index) => {
                return (
                    <React.Fragment key={livePrice.title}>
                        <Link
                            className={'width-100 pointer height-100'}
                            href={livePrice.linkUrl}
                            linkType={'vertical'}
                            onClick={() => handleTrackLinkout(livePrice.linkUrl)}
                            target={'_blank'}
                        >
                            <div className={`flex-center ${styles.livePriceTile}`}>
                                <IconImage
                                    height={18}
                                    url={livePrice.iconUrl}
                                    width={20}
                                />
                                <div className={`flex-column ${styles.text}`}>
                                    <HtmlTextWrapper
                                        className={'font-size-12'}
                                        htmlText={livePrice.htmlTitle}
                                    />
                                    <HtmlTextWrapper
                                        className={'font-size-10'}
                                        htmlText={livePrice.price.htmlText}
                                    />
                                </div>
                            </div>
                        </Link>
                        {index !== livePrices.length - 1 && (
                            <div className={'padding-x-15 width-100'}>
                                <Divider color={'alto'} />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
}
